import { FC } from 'react';

import { Match } from '@common/clients/api';

import styles from './ScoreElement.module.scss';

export interface Props {
    match: Match;
    isHorizontal?: boolean; // false by default
    isRectangleBlock?: boolean; // false by default
    hasDarkBackground?: boolean; // false by default;
}

export const ScoreElement: FC<Props> = ({ match, isRectangleBlock, isHorizontal, hasDarkBackground }) => {
    const classes = ['ScoreElement', styles.ScoreElement];

    if (isRectangleBlock) classes.push(styles['is-block']);
    if (isHorizontal) classes.push(styles['is-horizontal']);
    if (hasDarkBackground) {
        classes.push(styles['has-dark-background']);
    } else {
        classes.push('inverted');
    }

    return (
        <ul className={classes.join(' ')}>
            <li>{match.homeScore ?? '-'}</li>
            <li>{match.awayScore ?? '-'}</li>
        </ul>
    );
};
